// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/limton/limton-corporation-header-image.jpeg";
import Dollar from "../images/confi/dollar.svg";
/*import Globe from "../images/confi/globe.svg";
import Handshake from "../images/confi/handshake.svg";*/
import Sizeicon from "../images/limton/size-green.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import Giulio from "../images/limton/limton-corporation-logo.png";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("limton-corporation.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("limton-corporation.subTitle"),
        colorClass: "colorLight",
      },
      /* cta: {
        text: t("limton-corporation.topCta"),
        colorClass: "colorLight",
        url: t("limton-corporation.ctaUrl"),
        type: "mainColor",
      }, */
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("limton-corporation.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("limton-corporation.newMarketsValue"),
        },
        subtitle: {
          text: t("limton-corporation.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Sizeicon,
          alt: t("limton-corporation.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.roiValue"),
          textAfter: " %",
          isNum: true,
        },
        subtitle: {
          text: t("limton-corporation.roiSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Dollar,
          alt: t("limton-corporation.roiAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.recruitingValue"),
        },
        subtitle: {
          text: t("limton-corporation.recruitingSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Sizeicon,
          alt: t("limton-corporation.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("limton-corporation.locationTitle"),
        },
        subtitle: {
          text: t("limton-corporation.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("limton-corporation.locationAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.productTitle"),
        },
        subtitle: {
          text: t("limton-corporation.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("limton-corporation.productAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.memberTitle"),
        },
        subtitle: {
          text: t("limton-corporation.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("limton-corporation.memberAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.membershipTitle"),
        },
        subtitle: {
          text: t("limton-corporation.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("limton-corporation.membershipAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.sizeTitle"),
        },
        subtitle: {
          text: t("limton-corporation.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("limton-corporation.sizeAlt"),
        },
      },
      {
        title: {
          text: t("limton-corporation.sinceTitle"),
        },
        subtitle: {
          text: t("limton-corporation.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("limton-corporation.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("limton-corporation.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("limton-corporation.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("limton-corporation.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("limton-corporation.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("limton-corporation.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("limton-corporation.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    giulio: [
      {
        title: {
          text: t("limton-corporation.giulioName"),
        },
        subtitle: {
          text: t("limton-corporation.giulioTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Giulio,
          alt: t("limton-corporation.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("limton-corporation.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("limton-corporation.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("limton-corporation.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("limton-corporation.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("limton-corporation.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("limton-corporation.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("limton-corporation.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("limton-corporation.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("limton-corporation.bullet1"),
          t("limton-corporation.bullet2"),
          t("limton-corporation.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText:  [
      {
        text: t("limton-corporation.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("limton-corporation.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("limton-corporation.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("limton-corporation.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("limton-corporation.favoriteToolsExtra"),
    profileText: t("limton-corporation.profileText"),
    testimonyName: t("limton-corporation.giulioName"),
    testimonyTitle: t("limton-corporation.giulioTitle"),
    anchorText: t("limton-corporation.anchorText")
  };
};
